import { Course } from "@/types/Course";
import { GetStaticProps, NextPage } from "next";
import Container from "src/components/atoms/Container";
import DefaultLayout from "src/components/layouts/DefaultLayout";
import HighligtedCourses from "src/components/organisms/HIghlightedCourses";
import Hero from "src/components/templates/Home/Hero";
import PrimaryFeatures from "src/components/templates/Home/PrimaryFeatures";
import { useAuth } from "src/contexts/AuthContext";
import { getCourses } from "src/services/DefaultService";
import SinglePageService from "src/services/SinglePageService";

const Home: NextPage<{ data: any; courses: Course[] }> = ({
  data,
  courses,
}) => {
  const { user } = useAuth();
  const hero = data.attributes.content.find(
    (c: any) => c.__component === "shared.hero"
  );
  const sectionTitle = data.attributes.content.find(
    (c: any) => c.__component === "shared.section-title"
  );
  const features = data.attributes.content.find(
    (c: any) => c.__component === "page.features"
  );

  return (
    <DefaultLayout seo={data.attributes.seo}>
      <Hero
        {...hero}
        button={{
          label: "Ver cursos",
          url: "/cursos",
        }}
      />
      <section
        id="features"
        aria-label="Features for investing all your money"
        className={`bg-dark py-20 sm:py-36`}
      >
        <Container>
          <div className="mx-auto lg:mx-0 lg:w-1/2">
            <h2 className="text-3xl font-medium tracking-tight text-white">
              {sectionTitle?.title}
            </h2>
            <p className="mt-2 text-lg text-gray-200">
              {sectionTitle?.subTitle}
            </p>
          </div>
        </Container>
      </section>
      <PrimaryFeatures features={features.features} />
      {/* <Newsletter /> */}
      <HighligtedCourses courses={courses} />
    </DefaultLayout>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  try {
    const data = await SinglePageService.getPage({
      contentType: "home",
      populate: {
        content: {
          on: {
            "page.features": {
              populate: ["features", "features.media"],
            },
            "shared.hero": {
              populate: "*",
            },
            "shared.section-title": {
              populate: "*",
            },
          },
        },
      },
    });
    const sort = ["createdAt:desc"];
    const courses = await getCourses({
      key: "getCourses",
      pagination: {
        page: 1,
        pageSize: 3,
      },
      sort,
    });

    return {
      props: {
        data,
        courses: courses.data,
      },
      revalidate: 86400,
    };
  } catch (error: any) {
    console.log("Error creating page: ", error.message);
    throw error;
  }
};

export default Home;
