import { Course } from "@/types/Course";
import Container from "src/components/atoms/Container";
import HighligtedCourse from "src/components/molecules/HighligtedCourse";

interface HighligtedCoursesProps {
  courses: Course[];
}

const HighligtedCourses: React.FC<HighligtedCoursesProps> = ({ courses }) => {
  return (
    <Container className="my-8 lg:my-16">
      <div className="mb-8 text-center lg:mb-16">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Elige un curso y empieza a aprender
        </h2>
        <p className="mx-auto mt-3 max-w-3xl text-gray-500 sm:mt-4">
          Aprende programación con los cursos básicos y practica con los
          ejercicios dentro de cada curso.
        </p>
      </div>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
      >
        {courses.map((course) => (
          <li key={course.id}>
            <HighligtedCourse course={course} />
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default HighligtedCourses; // Path: components/organisms/HIghlightedCourses/index.tsx
