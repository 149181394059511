import { Course } from "@/types/Course";
import { getUploadUrl } from "@/utils/uploadUtils";
import Image from "next/image";
import Link from "next/link";
import Button from "src/components/atoms/Button";

interface HighligtedCourseProps {
  course: Course;
}

const HighligtedCourse: React.FC<HighligtedCourseProps> = ({ course }) => {
  const { id, attributes } = course;
  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <article
        key={course.id}
        className="flex flex-col items-start justify-between"
      >
        <div className="relative w-full">
          <Image
            src={getUploadUrl(attributes.image.data.attributes)}
            alt={attributes.image.data.attributes.alternativeText}
            className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
            width={attributes.image.data.attributes.width}
            height={attributes.image.data.attributes.height}
          />
          <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
        </div>
        <div className="max-w-xl px-4 py-5 sm:p-6">
          <div className="group relative">
            <h3 className="mt-3 h-10 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
              <Link href={`/cursos/${attributes.slug}`}>
                <span className="absolute inset-0" />
                {attributes.title}
              </Link>
            </h3>
            <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
              {attributes.description}
            </p>
            <p className="my-4">
              <Link href={`/cursos/${attributes.slug}`}>
                <Button color="primary" type="button" variant="solid" block>
                  Ver Curso
                </Button>
              </Link>
            </p>
          </div>
        </div>
      </article>
    </div>
  );
};

export default HighligtedCourse;
